import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { HeaderSection, EpisodePlayPauseButton, MediaContext } from '../components'
import styles from './episode.module.sass'
import EpisodeDetails from '../components/EpisodeDetails'
import SEO from '../components/SEO'

export default function EpisodePage({ pageContext: { slug } }) {
  const { episodes, setEpisode } = useContext(MediaContext)
  const epIndex = episodes.findIndex(ep => ep.slug === slug)
  const ep = episodes[epIndex]
  const nextEp = episodes[epIndex - 1] // reverse-chronoloigcal order
  const prevEp = episodes[epIndex + 1]

  useEffect(() => {
    setEpisode(ep)
  }, [])

  return (
    <>
      <SEO title={ep.title} description={ep.excerpt} />
      <HeaderSection
        className={styles.headerSection}
        title={ep.title}
        level={1}
        titleChildren={
          <EpisodePlayPauseButton
            className={styles.playPauseButton}
            episode={ep}
            white
          />
        }
      />

      <EpisodeDetails episode={ep} nextEp={nextEp} prevEp={prevEp} />
    </>
  )
}
EpisodePage.propTypes = {
  pageContext: PropTypes.object
}
